import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import gtag from 'ga-gtag'

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class FeaturesTiles extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-tiles section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-tiles-inner section-inner',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Sylvanium Stake Pool 1',
      paragraph: 'Stake Pool Id: edca900e81735725b8ea9dad885bb6ab17851a42f3f762433f133bf4',
      paragraph2: 'Ticker: SYLV1',
      paragraph3: 'Fee: 3%'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div>
          <div>
            <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" data-reveal-delay="150" />
            
            <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/monitoring2.jpg')}
                        alt="Features tile icon 01"
                        width={56}
                        height={56} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Uptime, Alerting and Monitoring
                    </h4>
                    <p className="alignLeft m-0 text-sm">
                    Our servers are operational 24/7, and under continuous monitoring. Alerts are generated immediately for any unexpected condition. 
                    </p>
                   
                    
                  </div>
                </div>
              </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="300">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/rocket.jpg')}
                        alt="Features tile icon 03"
                        width={56}
                        height={56} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                    Track Record
                    </h4>
                    <p className="alignLeft m-0 text-sm">
                      We have lift-off ! 
                      Our pool is consistently and reliably minting blocks every epoch. Stake with us and you will not be dissapointed. 
                      <Image
                        src={require('./../../assets/images/award2.png')}
                        alt="Features tile icon 02"
                        width={186}
                         />       
                    </p>
                  </div>
                </div>
              </div>


            <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="150">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/why.jpg')}
                        alt="Features tile icon 02"
                        width={56}
                        height={56} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                    Why stake with us
                    </h4>
                    <p className="alignLeft wider m-0 text-sm">
                    We are a green, pro environment staking pool.
                    <br/>
                     We donate a part of our earning to forest conservation projects. 
                     <br/>
                     We not only contribute to Cardano network stability, we do it in a nature-friendly way. We post our donation receipts on this website.
                    <br/>
                    Our goal is to provide the best staking experience with maximum rewards to the delegators of our stake pool 
                    <br/>
                    </p>
                  </div>
                </div>
              </div>      


              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="150">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/minibank.jpg')}
                        alt="Features tile icon 02"
                        width={56}
                        height={56} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                    Key Points about Staking
                    </h4>
                    <p className="alignLeft m-0 text-sm">
                        
                        Staking is a very safe operation that earns rewards. In a way, is like owning your own minibank.
                        <br/>
                        During Staking, the ADA never leave your wallet and you have full control on your ADA!
                        <br/>
                        You can withdraw from the staking at anytime and there is no downside
                        <br/>
                        <a href = "https://cardano.org/stake-pool-delegation/">Learn more about staking</a>
                        <br/>
                        <br/>

                    </p>
                  </div>
                </div>
              </div>         







              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="150">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/wheels.jpg')}
                        alt="Features tile icon 02"
                        width={56}
                        height={56} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Reliability
                    </h4>
                    <p className="alignLeft m-0 text-sm">
                    Our state of the art dedicated servers are configured for performance and high availability. 
                    <br/>
                    We run a redundant, geo-located topology, with one compute node in the United States, and two relay nodes, one in the United 
                    States, and one in Germany. Our servers have 4 cores, 8GB RAM and 128GB SSD. 
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="300">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/brain.jpg')}
                        alt="Features tile icon 03"
                        width={56}
                        height={56} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                    Experience
                    </h4>
                    <p className="alignLeft m-0 text-sm">
                      We have 20 years of software development and system engineering experience. 

                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

const placeholderStyle = {
  paddingBottom: '80px'
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

function trackDownloadEvent() {
  gtag('event', 'stakepool_page_visit', {
    some_event: 'some event title',
  })
}

export default FeaturesTiles;