import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class FeaturesTilesHome extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-tiles section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-tiles-inner section-inner',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: '',
      paragraph: '',
      paragraph2: '',
      paragraph3: ''
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div>
          <div>
            <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" data-reveal-delay="150" />
            
            <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-40">
                      <Image
                        src={require('./../../assets/images/monitoring2.jpg')}
                        alt="Features tile icon 01"
                        width={56}
                        height={56} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-40">
                      Current Programs
                    </h4>
                    <p className="alignLeft m-0 text-sm">
                      Allocation to forest conservation projects from profits from our Cardano staking pool:
                      <Link className="button" to={{pathname: '/stakingpool'}}>Sylvanium Stake Pool</Link>
                    </p>
                    
                  </div>
                </div>
              </div>

            
              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="150">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/wheels.jpg')}
                        alt="Features tile icon 02"
                        width={56}
                        height={56} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Why are trees important
                    </h4>
                    <p className="alignLeft m-0 text-sm">
                    <ul>
                    
                    <li>
                        Trees sequester carbon, helping to remove carbon dioxide and other greenhouse gases from the air, which cools the earth. In fact, a mature canopy tree absorbs enough carbon and releases enough oxygen to sustain two human beings!
                    </li>

                    <li>
                    Trees improve air quality by producing oxygen. They also store carbon, offsetting harmful byproducts of fossil-fuel burning.
                    </li>

                    <li>
                    Trees preserve biodiversity and provide habitat to wildlife
                    </li>

                    <li>
                    There are 3 trillion trees on earth, but are disappearing fast. Around 15 billion are lost every year due to deforestation, forest management and changes in land use, the research published in the journal Nature found.
                    </li>

                    <li>
                    Trees  help to reduce ozone levels in urban areas. 
                    </li>

                    <li>
                    They moderate the effects of sun and wind, and they clean the air by trapping dust, pollen and other pollutants.
                    </li>

                    <li>
                    Planted in the right places around buildings, trees can significantly reduce air-conditioning costs. This means less Freon, an inflammable and odorless chemical emitted from air conditioners. It is considered to have damaging effects to the ozone layer.
                    </li>

                    <li>
                    Trees increase property value.
                    </li>

                    <li>
                    Trees generate jobs and contribute raw materials for buildings, newspapers, books, etc. They are renewable, biodegradable and recyclable.
                    </li>

                    <li>
                    They provide shelter and food for wildlife such as birds, squirrels and bugs.
                    </li>

                    <li>
                    Trees make people feel good. Workers are more productive when they see trees along their commute routes and from their office windows. Landscapes that include trees help relax us, lower heart rates and reduce stress.
                    </li>

                    <li>
                    Trees heal: Studies have shown that patients with views of trees out their windows heal faster and with less complications. Children with ADHD show fewer symptoms when they have access to nature. Exposure to trees and nature aids concentration by reducing mental fatigue.
                    </li>

                    <li>
                    Trees help prevent water pollution: Trees reduce runoff by breaking rainfall thus allowing the water to flow down the trunk and into the earth below the tree. This prevents stormwater from carrying pollutants to the ocean. When mulched, trees act like a sponge that filters this water naturally and uses it to recharge groundwater supplies.
                    </li>

                    </ul>
                    </p>
                  </div>
                </div>
              </div>

             
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const placeholderStyle = {
  paddingBottom: '80px'
}

FeaturesTilesHome.propTypes = propTypes;
FeaturesTilesHome.defaultProps = defaultProps;

export default FeaturesTilesHome;